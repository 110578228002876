import React, { useState, useCallback, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Draggable from 'react-draggable';
import "./App.css";

const TableDesigner = () => {
  const [tables, setTables] = useState([]);
  const [selectedButton, setSelectedButton] = useState(null);
  const [nextTablePosition, setNextTablePosition] = useState({ x: 20, y: 20 });
  const [jsonInput, setJsonInput] = useState('');
  const [hoveredRelation, setHoveredRelation] = useState(null);
  const [popupMessage, setPopupMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [collapsedTables, setCollapsedTables] = useState({});

  useEffect(() => {
    const savedData = localStorage.getItem('tableDesignerData');
    if (savedData) {
      try {
        const parsedData = JSON.parse(savedData);
        setTables(parsedData.tables.map(table => ({
          ...table,
          id: uuidv4(),
          fields: table.fields.map(field => ({ ...field, id: uuidv4() }))
        })));
      } catch (error) {
        console.error('Error loading data from localStorage:', error);
      }
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      saveToLocalStorage();
    }, 180000); // 3 minutes in milliseconds

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, [tables]);

  const handleMouseEnter = (tableName, fieldName) => {
    setHoveredRelation({ tableName, fieldName });
  };
  
  const handleMouseLeave = () => {
    setHoveredRelation(null);
  };

  const popup = (message) => {
    setPopupMessage(message);
    setShowPopup(true);
    setTimeout(() => {
      setShowPopup(false);
      setPopupMessage('');
    }, 3000); // 3 seconds
  };

  const addTable = () => {
    const newTable = {
      id: uuidv4(),
      name: `Table_${tables.length + 1}`,
      x: nextTablePosition.x,
      y: nextTablePosition.y,
      fields: [],
    };
    setTables([...tables, newTable]);
    setNextTablePosition(prev => ({
      x: prev.x + 220,
      y: prev.x > 800 ? prev.y + 300 : prev.y
    }));
  };

  const addField = (tableId) => {
    setTables(tables.map(table => 
      table.id === tableId
        ? { ...table, fields: [...table.fields, { id: uuidv4(), name: '', type: '', change: false, default: '', required: false }] }
        : table
    ));
  };

  const updateField = (tableId, fieldId, name, type, change, defaultValue, required) => {
    setTables(tables.map(table => 
      table.id === tableId
        ? {
            ...table,
            fields: table.fields.map(field => 
              field.id === fieldId ? { ...field, name, type, change, default: defaultValue, required } : field
            )
          }
        : table
    ));
  };

  const updateTableName = (tableId, name) => {
    setTables(tables.map(table => 
      table.id === tableId ? { ...table, name } : table
    ));
  };

  const handleDragStop = (tableId, e, data) => {
    setTables(tables.map(table => 
      table.id === tableId ? { ...table, x: data.x, y: data.y } : table
    ));
  };

  const handleButtonClick = (tableId, fieldId, isType) => {
    if (selectedButton) {
      if (selectedButton.tableId !== tableId || selectedButton.fieldId !== fieldId) {
        const fromTable = tables.find(t => t.id === selectedButton.tableId);
        const fromField = fromTable.fields.find(f => f.id === selectedButton.fieldId);
        const toTable = tables.find(t => t.id === tableId);
        const toField = toTable.fields.find(f => f.id === fieldId);

        setTables(tables.map(table => 
          table.id === tableId
            ? {
                ...table,
                fields: table.fields.map(field => 
                  field.id === fieldId
                    ? { ...field, type: `${fromTable.name}.${fromField.name}` }
                    : field
                )
              }
            : table
        ));
      }
      setSelectedButton(null);
    } else {
      setSelectedButton({ tableId, fieldId, isType });
    }
  };

  const getFieldPosition = useCallback((tableName, fieldName, isType) => {
    const table = tables.find(t => t.name === tableName);
    if (!table) return { x: 0, y: 0 };
    const fieldIndex = table.fields.findIndex(f => f.name === fieldName);
    return {
      x: table.x - 5 + (isType ? 330 : 10),
      y: table.y + 75 + fieldIndex * 25,
    };
  }, [tables]);

  const renderRelationships = useCallback(() => {
    return tables.flatMap(table =>
      table.fields.filter(field => field.type.includes('.')).map(field => {
        const [fromTableName, fromFieldName] = field.type.split('.');
        const from = getFieldPosition(fromTableName, fromFieldName, false);
        const to = getFieldPosition(table.name, field.name, true);

        // Ensure valid positions
        if (from.x === 0 && from.y === 0 || to.x === 0 && to.y === 0) return null;

        // Calculate points to avoid crossing the tables
        const fromRight = { x: from.x + 10, y: from.y };
        const toLeft = { x: to.x - 10, y: to.y };
        const midX = (fromRight.x + toLeft.x) / 2;

        const isHovered = hoveredRelation && (
          (hoveredRelation.tableName === table.name && hoveredRelation.fieldName === field.name) ||
          (hoveredRelation.tableName === fromTableName && hoveredRelation.fieldName === fromFieldName)
        );

        return (
          <svg 
            key={`${table.name}.${field.name}.${fromTableName}.${fromFieldName}`} 
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', pointerEvents: 'none' }}
            className={`relationship-line ${isHovered ? 'hovered' : ''}`}
            onMouseEnter={() => handleMouseEnter(table.name, field.name)}
            onMouseLeave={handleMouseLeave}
          >
            <line className="relationship-line-part" x1={fromRight.x} y1={fromRight.y} x2={midX} y2={fromRight.y} stroke="black" strokeWidth={2} />
            <line className="relationship-line-part" x1={midX} y1={fromRight.y} x2={midX} y2={toLeft.y} stroke="black" strokeWidth={2} />
            <line className="relationship-line-part" x1={midX} y1={toLeft.y} x2={toLeft.x} y2={toLeft.y} stroke="black" strokeWidth={2} />
          </svg>
        );
      })
    ).filter(Boolean);
  }, [tables, getFieldPosition, hoveredRelation]);

  const deleteTable = (tableId) => {
    setTables(tables.filter(table => table.id !== tableId));
  };

  const deleteField = (tableId, fieldId) => {
    setTables(tables.map(table => 
      table.id === tableId
        ? { ...table, fields: table.fields.filter(field => field.id !== fieldId) }
        : table
    ));
  };

  const moveFieldUp = (tableId, fieldIndex) => {
    setTables(tables.map(table => {
      if (table.id === tableId && fieldIndex > 0) {
        const newFields = [...table.fields];
        const temp = newFields[fieldIndex - 1];
        newFields[fieldIndex - 1] = newFields[fieldIndex];
        newFields[fieldIndex] = temp;
        return { ...table, fields: newFields };
      }
      return table;
    }));
  };

  const moveFieldDown = (tableId, fieldIndex) => {
    setTables(tables.map(table => {
      if (table.id === tableId && fieldIndex < table.fields.length - 1) {
        const newFields = [...table.fields];
        const temp = newFields[fieldIndex + 1];
        newFields[fieldIndex + 1] = newFields[fieldIndex];
        newFields[fieldIndex] = temp;
        return { ...table, fields: newFields };
      }
      return table;
    }));
  };

  const toggleCollapse = (tableId) => {
    setCollapsedTables(prevState => ({
      ...prevState,
      [tableId]: !prevState[tableId]
    }));
  };

  const jsonOutput = JSON.stringify({
    tables: tables.map(table => ({
      name: table.name,
      x: table.x,
      y: table.y,
      fields: table.fields.map(field => ({
        name: field.name, 
        type: field.type, 
        change: field.change, 
        default: field.default, 
        required: field.required
      }))
    }))
  }, null, 2);

  const saveJson = () => {
    const jsonOutput = JSON.stringify({
      tables: tables.map(table => ({
        name: table.name,
        x: table.x,
        y: table.y,
        fields: table.fields.map(field => ({
          name: field.name, 
          type: field.type, 
          change: field.change, 
          default: field.default, 
          required: field.required
        }))
      }))
    }, null, 2);

    const blob = new Blob([jsonOutput], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'database_design.json';
    a.click();
    URL.revokeObjectURL(url);
  };

  const saveToLocalStorage = () => {
    const jsonOutput = JSON.stringify({
      tables: tables.map(table => ({
        name: table.name,
        x: table.x,
        y: table.y,
        fields: table.fields.map(field => ({
          name: field.name, 
          type: field.type, 
          change: field.change, 
          default: field.default, 
          required: field.required
        }))
      }))
    });
    localStorage.setItem('tableDesignerData', jsonOutput);
    popup('Data saved to localStorage');
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const parsedJson = JSON.parse(e.target.result);
          if (parsedJson.tables) {
            setTables(parsedJson.tables.map(table => ({
              ...table,
              id: uuidv4(),
              fields: table.fields.map(field => ({ ...field, id: uuidv4() }))
            })));
          } else {
            popup('Invalid JSON format');
          }
        } catch (error) {
          popup('Error parsing JSON: ' + error.message);
        }
      };
      reader.readAsText(file);
    }
  };

  const loadJson = () => {
    try {
      const parsedJson = JSON.parse(jsonInput);
      if (parsedJson.tables) {
        setTables(parsedJson.tables.map(table => ({
          ...table,
          id: uuidv4(),
          fields: table.fields.map(field => ({ ...field, id: uuidv4() }))
        })));
        setJsonInput('');
      } else {
        popup('Invalid JSON format');
      }
    } catch (error) {
      popup('Error parsing JSON: ' + error.message);
    }
  };

  return (
    <div className='documentContainer'>
      <div className='topButtons'>
        {showPopup && (
          <div className="popup">
            {popupMessage}
          </div>
        )}
      </div>
      <div className='tableContainerFrame'>
        <div className="tableContainer" style={{ position: 'relative', width: '400%', height: '3200px' }}>
          {tables.map(table => (
            <Draggable
              key={table.id}
              defaultPosition={{ x: table.x, y: table.y }}
              onStop={(e, data) => handleDragStop(table.id, e, data)}
              bounds="parent"
            >
              <div className="tableTable" style={{ position: 'absolute' }}>
                <div className='tableHeader'>
                  <button onClick={() => deleteTable(table.id)} className='buttonDanger'>x</button>
                  <h3>{table.name}</h3>
                </div>
                {table.fields.map((field, index) => (
                  <div key={field.id} className="tableDrag" style={{ display: 'flex' }}>
                    <button
                      className='buttonRelation'
                      onClick={() => handleButtonClick(table.id, field.id, false)}
                      style={{ cursor: 'pointer', backgroundColor: selectedButton && !selectedButton.isType && selectedButton.tableId === table.id && selectedButton.fieldId === field.id ? 'yellow' : 'transparent' }}
                    >
                      •
                    </button>
                    <div className='tableInTable'>
                      <div style={{ flex: 1 }}>
                        <p style={{ textDecoration: field.change ? "underline" : " ", color: field.change ? "blue" : " " }}>{field.name || 'Field Name'}<b style={{ color: 'black' }}>{field.required ? ' *' : ' '}</b></p>
                      </div>
                      <div style={{ flex: 1 }}>
                        <p style={{ textDecoration: field.change ? "underline" : " ", color: field.change ? "blue" : " " }}>{field.type || 'Field Type'}<b style={{ color: 'black' }}>{field.required ? ' *' : ' '}</b></p>
                      </div>
                    </div>
                    <button
                      className='buttonRelation'
                      onClick={() => handleButtonClick(table.id, field.id, true)}
                      style={{ cursor: 'pointer', backgroundColor: selectedButton && selectedButton.isType && selectedButton.tableId === table.id && selectedButton.fieldId === field.id ? 'yellow' : 'transparent' }}
                    >
                      •
                    </button>
                  </div>
                ))}
              </div>
            </Draggable>
          ))}
          {renderRelationships()}
        </div>
      </div>
      <div className="editContainer" style={{ top: 0, right: 0 }}>
        <div className='editContButtonsTop'>
          <button onClick={addTable}><b>+ Table</b></button>
          <button onClick={saveJson} className='btnSecondary'>Save JSON</button>{' '}
          <button onClick={saveToLocalStorage}>Save to localStorage</button>
        </div>
        {tables.map(table => (
          <div className="editTable" key={table.id}>
            <div className='editTableFieldsArray'>
              <div>
                <input
                  value={table.name}
                  className='fieldTitle'
                  onChange={(e) => updateTableName(table.id, e.target.value)}
                  style={{ fontWeight: 'bold' }}
                />
                <button style={{ marginLeft: '1rem' }} onClick={() => addField(table.id)}>+</button>
                <button onClick={() => toggleCollapse(table.id)}>{collapsedTables[table.id] ? 'Expand' : 'Collapse'}</button>
              </div>
              {!collapsedTables[table.id] && table.fields.map((field, index) => (
                <div className='editFields' key={field.id}>
                  <div>
                    <button onClick={() => deleteField(table.id, field.id)} className='buttonDanger'>x</button>
                  </div>
                  <div>
                    <input
                      value={field.name}
                      onChange={(e) => updateField(table.id, field.id, e.target.value, field.type, field.change, field.default, field.required)}
                      placeholder="Field name"
                    />
                  </div>
                  <div>
                    <input
                      value={field.type}
                      onChange={(e) => updateField(table.id, field.id, field.name, e.target.value, field.change, field.default, field.required)}
                      placeholder="Field type"
                    />
                  </div>
                  <div>
                    <input
                      style={{ maxWidth: "50px" }}
                      value={field.default}
                      onChange={(e) => updateField(table.id, field.id, field.name, field.type, field.change, e.target.value, field.required)}
                      placeholder="Default"
                    />
                  </div>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        checked={field.required}
                        onChange={(e) => updateField(table.id, field.id, field.name, field.type, field.change, field.default, e.target.checked)}
                      /> *
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      checked={field.change}
                      onChange={(e) => updateField(table.id, field.id, field.name, field.type, e.target.checked, field.default, field.required)}
                    /> C
                  </div>
                  <div>
                    <button onClick={() => moveFieldUp(table.id, index)}>↑</button>
                    <button onClick={() => moveFieldDown(table.id, index)}>↓</button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className='bottomContainer'>
        <div>
          <h3>Save:</h3>
          <div>
            <input
              type="file"
              accept=".json"
              onChange={handleFileUpload}
            />
            <br />
            <button onClick={saveJson}>Save JSON</button>{' '}
            <button onClick={saveToLocalStorage}>Save to localStorage</button>
          </div>
        </div>
        <div>
          <h3>JSON Output:</h3>
          <pre style={{ maxHeight: '100px', overflow: 'auto', border: '1px solid black', padding: '10px' }}>
            {jsonOutput}
          </pre>
        </div>
      </div>
    </div>
  );
};

export default TableDesigner;
